import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import htmlSerializer from '../../utils/htmlSerializer'

// External styles from mailchimp widget pulled out so it can pop above some overrides
const mailChimpBaseStyles = `
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
`;

// Embed code pulled from Mailchimp
const mailChimpWidget = `
<div id="mc_embed_signup">
<form action="https://scootscience.us20.list-manage.com/subscribe/post?u=d94bd861a73748b824747f490&amp;id=b56f9b4af9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
\t<h2>Subscribe to our Mailing List</h2>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
\t<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
\t<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<p><a href="https://us20.campaign-archive.com/home/?u=d94bd861a73748b824747f490&id=b56f9b4af9" title="View previous campaigns">View previous campaigns.</a></p>
<div hidden="true"><input type="hidden" name="tags" value="2916587,2262652"></div>
\t<div id="mce-responses" class="clear">
\t\t<div class="response" id="mce-error-response" style="display:none"></div>
\t\t<div class="response" id="mce-success-response" style="display:none"></div>
\t</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d94bd861a73748b824747f490_b56f9b4af9" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[6]='COMPANY';ftypes[6]='text';fnames[3]='MMERGE3';ftypes[3]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
`;

// Little bit of styling directly over the widget to fit in better
const mailChimpStyles = `<style type="text/css">
    /* Adjusting embed to fit in just a bit here */
    /* Where possible, borrowed from other styling on the site. - MEA */
    #mc_embed_signup {clear:left; font:14px Helvetica,Arial,sans-serif;  min-width: 60vw; }
    #mc_embed_signup #mc-embedded-subscribe-form { text-align: center; }
    #mc_embed_signup #mc-embedded-subscribe-form h2 { font-size: 3.5vw; font-weight: normal; letter-spacing: -.02em; line-height: 110%; color: white; }
    #mc_embed_signup .indicates-required { display: none; }
    #mc_embed_signup .mc-field-group { text-align: left; color: white; max-width: 400px; margin: 30px auto 0; }
    #mc_embed_signup a { color: white; font-size: 14px; }
    #mc_embed_signup #mc-embedded-subscribe { background: #ff5549; padding: 15px 32px; height: auto; line-height: 1; margin-top: 6px; border-radius: 0; }
</style>`;


const MailingListSub = () => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: mailChimpBaseStyles }} />
            <div dangerouslySetInnerHTML={{__html: mailChimpStyles}} />
            <div className={'container-fluid'}>
                <div style={{
                    backgroundColor: '#498799',
                    display: 'flex',
                    width: '100%',
                    paddingTop: '10vw',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} dangerouslySetInnerHTML={{__html: mailChimpWidget}} />
            </div>
        </div>
    )
}

export default MailingListSub;
