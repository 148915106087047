import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-plugin-transition-link'
import 'intersection-observer'
import IsVisible from 'react-is-visible'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import htmlSerializer from '../../utils/htmlSerializer'
import Icon from '../icon/icon'
import { withPreview } from 'gatsby-source-prismic-graphql'

const RenderArticles = ({ data }) => (
  <div className="c-article-listing">
    <div className="container-fluid">
      <div className="row">
        <div className="c-article-listing__header">
          <h2 className="c-article-listing__header-title">{RichText.asText(data.articlestitle)}</h2>
        </div>
      </div>
      <div className="row">
        {data.articles.map(article => {
          if (article.articlelink === null) return null
          return (
            <div className="c-article-listing__article" key={article.articlelink._meta.id}>
              <Link
                to={linkResolver(article.articlelink._meta)}
                entry={{ delay: 0.5, length: 0 }}
                exit={{ length: 0.5 }}
                className="c-article-listing__article-link"
              >
                <IsVisible once>
                  {isVisible =>
                    <div className={`c-article-listing__article-icon${ isVisible ? ' is-visible' : '' }`}>
                      <Icon icon={article.articlelink.icon}></Icon>
                    </div>
                  }
                </IsVisible>
                <h3 className="c-article-listing__article-title">{RichText.asText(article.articlelink.title)}</h3>
                <div className="c-article-listing__article-summary cms-content">
                  {RichText.render(article.articlelink.summary, linkResolver, htmlSerializer)}
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export const ArticleListing = () => {
  return (
    <>
      <StaticQuery query={`${ staticQuery }`} render={withPreview(data => <RenderArticles data={data.prismic.allHomepages.edges.slice(0, 1).pop().node} />, staticQuery)} />
    </>
  )
}

const staticQuery = graphql`
  query {
    prismic {
      allHomepages {
        edges {
          node {
            articlestitle
            articles {
              articlelink {
                ... on PRISMIC_Article {
                  title
                  summary
                  _meta {
                    id
                    uid
                    type
                  }
                  icon
                }
              }
            }
          }
        }
      }
    }
  }
`
