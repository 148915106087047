import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Masthead from '../components/masthead/masthead'
import HomeFeature from '../components/homefeature/homefeature'
import { ArticleListing } from '../components/articlelisting/articlelisting'
import { PartnerListing } from '../components/partnerlisting/partnerlisting'
import MailingListSub from "../components/mailinglistsub/mailinglistsub";

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            homefeaturecontent1
            homefeaturecontent2
            homefeatureimage
            homefeaturetitle1
            homefeaturetitle2
            homefeaturevimeoid
            metadescription
            metatitle
            title
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = RichText.asText(doc.node.metadescription)
  return (
    <Layout footerClass="c-footer--home">
      <SEO
        title={metaTitle}
        description={metaDescription}
      />
      <Masthead title={title} link={true}></Masthead>
      <HomeFeature doc={doc} />
      <ArticleListing />
      <PartnerListing />
      <MailingListSub />
    </Layout>
  )
}
