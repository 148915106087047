import React from "react";
import IsVisible from "react-is-visible";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";
import htmlSerializer from "../../utils/htmlSerializer";
import { document } from "browser-monads";
import Link from "gatsby-plugin-transition-link";

class HomeFeature extends React.Component {
  state = { showVimeo: false };

  toggleVimeo = () => {
    this.setState({
      showVimeo: !this.state.showVimeo,
    });

    if (document.body.classList.contains("is-vimeo-popup")) {
      document.body.classList.remove("is-vimeo-popup");
    } else {
      document.body.classList.add("is-vimeo-popup");
    }
  };
  render() {
    const vimeoState = this.state.showVimeo ? " is-visible" : "";
    const doc = this.props.doc.node;
    return (
      <div className="c-home-feature">
        <div className="c-home-feature__bg-video-container">
          <video
            muted
            loop
            playsInline
            autoPlay
            className="c-home-feature__bg-video"
          >
            <source
              src="/videos/ocean.webm"
              type='video/webm;codecs="vp8, vorbis"'
            />
            <source
              src="/videos/ocean.mp4"
              type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
        <div className="c-home-feature__particles-container">
          <video
            muted
            loop
            playsInline
            autoPlay
            className="c-home-feature__particles-video"
          >
            <source
              src="/videos/particles.webm"
              type='video/webm;codecs="vp8, vorbis"'
            />
            <source
              src="/videos/particles.mp4"
              type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="c-home-feature__content c-home-feature__content--1st">
              <div className="c-home-feature__content-wrapper">
                <h2 className="c-home-feature__content-title">
                  {RichText.asText(doc.homefeaturetitle1)}
                </h2>
                <div className="c-home-feature__content-summary cms-content">
                  {RichText.render(
                    doc.homefeaturecontent1,
                    linkResolver,
                    htmlSerializer
                  )}
                </div>
                <Link
                  to="/product"
                  className="c-home-feature__content-link"
                  entry={{ delay: 0.5, length: 0 }}
                  exit={{ length: 0.5 }}
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="c-home-feature__content c-home-feature__content--2nd">
              <div className="c-home-feature__content-wrapper">
                <h2 className="c-home-feature__content-title">
                  {RichText.asText(doc.homefeaturetitle2)}
                </h2>
                <div className="c-home-feature__content-summary cms-content">
                  {RichText.render(
                    doc.homefeaturecontent2,
                    linkResolver,
                    htmlSerializer
                  )}
                </div>
                <Link
                  to="/product"
                  className="c-home-feature__content-link"
                  entry={{ delay: 0.5, length: 0 }}
                  exit={{ length: 0.5 }}
                >
                  Learn how
                </Link>
              </div>
            </div>
            <div className="c-home-feature__video">
              {RichText.asText(doc.homefeaturevimeoid) !== "" ? (
                <button
                  className="c-home-feature__video-wrapper"
                  onClick={this.toggleVimeo}
                >
                  {doc.homefeatureimage && (
                    <IsVisible once>
                      {(isVisible) => (
                        <div
                          className={`c-home-feature__video-image${
                            isVisible ? " is-visible" : ""
                          }`}
                        >
                          <img
                            src={doc.homefeatureimage.low.url}
                            data-src={doc.homefeatureimage.url}
                            className="lazyload"
                            alt=""
                          />
                        </div>
                      )}
                    </IsVisible>
                  )}
                  <div className="c-home-feature__video-link-container">
                    <div className="c-home-feature__video-link c-btn">Play</div>
                  </div>
                </button>
              ) : (
                <div className="c-home-feature__video-wrapper">
                  {doc.homefeatureimage && (
                    <IsVisible once>
                      {(isVisible) => (
                        <div
                          className={`c-home-feature__video-image${
                            isVisible ? " is-visible" : ""
                          }`}
                        >
                          <img
                            src={doc.homefeatureimage.low.url}
                            data-src={doc.homefeatureimage.url}
                            className="lazyload"
                            alt=""
                          />
                        </div>
                      )}
                    </IsVisible>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {RichText.asText(doc.homefeaturevimeoid) !== "" && (
          <div className={`c-home-feature__vimeo-popup${vimeoState}`}>
            <div className="c-home-feature__vimeo">
              <iframe
                src={
                  this.state.showVimeo
                    ? `https://player.vimeo.com/video/${RichText.asText(
                        doc.homefeaturevimeoid
                      )}`
                    : ""
                }
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <button
              className="c-home-feature__vimeo-close c-btn"
              onClick={this.toggleVimeo}
            >
              <svg fill="none" viewBox="0 0 30 31">
                <path
                  stroke="#fff"
                  strokeWidth="2"
                  d="M.9914 1.2929l28.2843 28.2843M28.9914 1.7071L.7071 29.9914"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default HomeFeature;
