import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import htmlSerializer from '../../utils/htmlSerializer'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

const RenderPartners = ({ data }) => (
  <div className="c-partner-listing">
    <div className="container-fluid">
      <div className="row">
        <div className="c-partner-listing__header">
          <h2 className="c-partner-listing__header-title">{RichText.asText(data.partnerstitle)}</h2>
          <div className="c-partner-listing__header-summary cms-content">
            {RichText.render(data.partnerssummary, linkResolver, htmlSerializer)}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="c-partner-listing__list-items">
          {data.partners.map((partner, index) => {
            if (partner === null) return null
            return (
              <div className="c-partner-listing__list-item" key={`partner-${ index }`}>
                {partner.partnerimage && (
                  <div className="c-partner-listing__list-image">
                    <img data-src={partner.partnerimage.url} className="lazyload" alt="" />
                  </div>
                )}
                {partner.partnerquote && RichText.asText(partner.partnerquote) && (
                  <blockquote className="c-partner-listing__list-quote-container">
                    <p className="c-partner-listing__list-quote">{partner.partnerquote && RichText.asText(partner.partnerquote)}</p>
                    <footer className="c-partner-listing__list-quote-credit">
                      <p>{partner.partnerauthor && RichText.asText(partner.partnerauthor)}</p>
                      <p>{partner.partnercompany && RichText.asText(partner.partnercompany)}</p>
                    </footer>
                  </blockquote>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
)

export const PartnerListing = () => {
  return (
    <>
      <StaticQuery query={`${ staticQuery }`} render={withPreview(data => <RenderPartners data={data.prismic.allHomepages.edges.slice(0, 1).pop().node} />, staticQuery)} />
    </>
  )
}

const staticQuery = graphql`
  query {
    prismic {
      allHomepages {
        edges {
          node {
            partnerstitle
            partnerssummary
            partners {
              partnerauthor
              partnercompany
              partnerimage
              partnerquote
            }
          }
        }
      }
    }
  }
`
